import React from 'react'
// import { Location } from '@reach/router'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { colors, md } from '../common'

export default () => {
  React.useEffect(() => {
    (function (b, c, a, d, f, g, h, k, l) { var e = c.getElementsByTagName(a)[0]; a = c.createElement(a); var m = function (a) { delete b[d]; a(c.getElementById(f), [g, k, h, l]) }; b[d] = b[d] || m; a.async = !0; a.src = "https://corners.rolex.com/rlx-corner.js"; e.parentNode.insertBefore(a, e) })(window, document, "script", "rlxCornerCallback", "rlx-corner", "c8181a49983723edacb987b3682aca31", "https://www.underwoodjewelers.com/privacy-policy/", "en")
  }, [])

  return (
    <Layout headSize={'small'}>
      <SEO
        title="Official Rolex Jeweler in Jacksonville"
        description="Underwood Jewelers in Jacksonville, FL is proud to be part of the worldwide network of Official Rolex Jewelers, allowed to sell and maintain Rolex watches." />

      <div css={`min-height:100vh; overflow:visible;`}>
        <div id="rlx-corner"></div>
      </div>

      <Wrap>
        <h1>Welcome to Underwood Jewelers, Official Rolex Jeweler</h1>
        <p>Only Official Rolex Jewelers can certify the authenticity of your new Rolex and back its five-year international guarantee. Underwood Jewelers is proud to be part of the worldwide network of Official Rolex Jewelers. Nothing beats experiencing first-hand the meticulous details, the balanced weight, the comfort and simply the feel of a Rolex watch. Visit us at Underwood Jewelers in either Ponte Vedra Beach or Jacksonville, Florida and we will guide you through the range of men’s &amp; women’s Rolex watches, and advise you on the technical aspects of Rolex watches including the Day-Date, Yacht-Master, Sea-Dweller and more.</p>
      </Wrap>

    </Layout>
  )
}

// export default () => {
//   return(
//     <Layout headSize={'small'}>
//       <SEO
//         title="Underwood Jewelers - Official Rolex Jeweler in Jacksonville"
//         description="Underwood Jewelers in Jacksonville, FL is proud to be part of the worldwide network of Official Rolex Jewelers, allowed to sell and maintain Rolex watches." />
//       <div css={`min-height:100vh;overflow:scroll;`}>
//         <iframe title="rolex mandated iframe" id="rolex-corner-v3" src="https://www.rolex.com/corners/official-rolex-retailer/c8181a49983723edacb987b3682aca31.html" scrolling="no" style={{border:0,width:'100%',minHeight:'100vh',overflow:'scroll'}}></iframe>
//       </div>
//       <Wrap>
//         <h1>Welcome to Underwood Jewelers, Official Rolex Jeweler</h1>
//         <p>Only Official Rolex Jewelers can certify the authenticity of your new Rolex and back its five-year international guarantee. Underwood Jewelers is proud to be part of the worldwide network of Official Rolex Jewelers. Nothing beats experiencing first-hand the meticulous details, the balanced weight, the comfort and simply the feel of a Rolex watch. Visit us at Underwood Jewelers and we will guide you through the range of men’s & women’s Rolex watches, and advise you on the technical aspects of Rolex watches including the Day-Date, Yacht-Master, Sea-Dweller and more.</p>
//       </Wrap>
//     </Layout>
// )}

// export default () => {
//   const windowGlobal = typeof window !== 'undefined' && window
//   return(
//     <Location>
//       {({ location }) => {
//         if (!location.hash && windowGlobal !== false) {
//             windowGlobal.location = windowGlobal.location + '#loaded'
//             windowGlobal.location.reload()
//         }
//         return <Layout headSize={'small'}>
//                   <SEO
//                     title="Rolex"
//                     description="Underwood’s in San Marco & Ponte Vedra are proud to be official Rolex jewelers in the Jacksonville area. We are also an authorized Rolex service center." />
//                   <div css={`min-height:100vh;overflow:scroll;`}>
//                     <iframe title="rolex mandated iframe" id="rolex-corner-v3" src="https://www.rolex.com/corners/official-rolex-retailer/c8181a49983723edacb987b3682aca31.html" scrolling="no" style={{border:0,width:'100%',minHeight:'100vh',overflow:'scroll'}}></iframe>
//                   </div>
//                   <Wrap>
//                     <h1>Rolex Luxury Watches</h1>
//                     <p>Underwood's of Jacksonville, Florida is proud to be part of the worldwide network of Official Rolex Jewelers, allowed to sell and maintain Rolex watches. With the necessary skills, technical know-how and special equipment, we guarantee the authenticity of each and every part of your Rolex. Contact us to book an appointment with our dedicated staff, who can help you make the choice that will last a lifetime.</p>
//                   </Wrap>
//                   </Layout>
//       }}
//     </Location>
// )}

const Wrap = styled.div`
  padding: 4rem 8rem;

  h1 {
    color:${colors.green};
    text-align:center;
    text-transform:uppercase;
    padding-bottom:2rem;
  }

  li {
    padding: .5rem 0;
  }

  ${md} {
    padding:1rem;
  }
`
